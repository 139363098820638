<template>
  <div id="shoppingList">
    <el-dialog
      :title="shoppingListFormTitle"
      width="1500px"
      :visible.sync="shoppingListDialogVisible"
      :close-on-click-modal="false"
      @close="shoppingListDialogClose"
    >
      <el-form
        ref="shoppingListFormRef"
        :model="shoppingListForm"
        :rules="shoppingListFormRules"
        label-position="right"
        label-width="60px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="purchaseListNo">
              <el-input v-model="shoppingListForm.purchaseListNo" placeholder="请输入编号" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        v-show="shoppingListFormTitle === '新增采购清单'"
        ref="requisitionTable"
        :data="requisitionList"
        row-key="id"
        :cell-style="{padding: '6px'}"
        :header-cell-style="{background: '#f8f8f9'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.detailList" row-key="id" border>
              <el-table-column prop="materialNo" label="采购物资编号" />
              <el-table-column prop="materialName" label="采购物品名称" />
              <el-table-column prop="factory" label="厂家" />
              <el-table-column prop="spec" label="规格/型号" />
              <el-table-column prop="unitPrice" label="单价" />
              <el-table-column prop="quantity" label="数量" />
              <el-table-column prop="estimatedPrice" label="总金额" />
              <el-table-column label="来源">
                <template>
                  <span v-if="props.row.type === 1">采购申请单</span>
                  <span v-if="props.row.type === 2">采购计划表</span>
                </template>
              </el-table-column>
              <el-table-column prop="remarks" label="备注" />
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="requisitionNo" label="采购计划表编号" />
        <el-table-column prop="applicant" label="申请人" />
        <el-table-column label="日期">
          <template slot-scope="scope">
            <span v-if="scope.row.applicationDate">{{ scope.row.applicationDate.substring(0, 10) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <vxe-table
        v-show="shoppingListFormTitle === '采购清单详情' || shoppingListFormTitle === '修改采购清单'"
        ref="xTableInfo"
        border
        resizable
        show-overflow
        keep-source
        :data="detailList"
        :edit-config="{trigger: 'click', mode: 'cell'}"
      >
        <vxe-table-column
          field="materialNo"
          title="编号"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="materialName"
          title="商品名称"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="spec"
          title="型号规格"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="factory"
          title="厂家"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="unitPrice"
          title="单价"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="quantity"
          title="数量"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column field="totalPrice" title="金额">
          <template #default="{ row }">
            <span>{{ countAmount(row) }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column field="source" title="来源" width="250">
          <template v-slot="{row,column}">
            <vxe-radio-group v-model="row[column.property]">
              <vxe-radio :label="1" content="采购申请单" />
              <vxe-radio :label="2" content="采购计划表" />
            </vxe-radio-group>
          </template>
        </vxe-table-column>
        <vxe-table-column
          field="remarks"
          title="备注"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
      </vxe-table>
      <vxe-table
        v-show="shoppingListFormTitle === '生成采购台账'"
        ref="xTable"
        border
        resizable
        show-overflow
        keep-source
        :data="detailList"
        :edit-config="{trigger: 'click', mode: 'cell'}"
      >
        <vxe-table-column
          field="materialName"
          title="商品名称"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="spec"
          title="型号规格"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="factory"
          title="厂家"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="type"
          title="物资类别"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="batchNo"
          title="批号"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="unitPrice"
          title="单价"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="quantity"
          title="数量"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column field="amount" title="金额">
          <template #default="{ row }">
            <span>{{ countAmount2(row) }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column
          field="purchaseDate"
          title="采购日期"
          width="150"
          :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
        />
        <vxe-table-column
          field="deliveryDate"
          title="到货日期"
          width="150"
          :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
        />
        <vxe-table-column field="agreement" title="质量/采购协议" width="150">
          <template v-slot="{row,column}">
            <vxe-radio-group v-model="row[column.property]">
              <vxe-radio :label="1" content="有" />
              <vxe-radio :label="2" content="无" />
            </vxe-radio-group>
          </template>
        </vxe-table-column>
        <vxe-table-column
          field="remarks"
          title="备注"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
      </vxe-table>
      <div slot="footer">
        <el-button @click="shoppingListDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="shoppingListFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input v-model="searchForm.purchaseListNo" placeholder="请输入编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CG_SHOPPING_LIST_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="shoppingListPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="purchaseListNo" label="编号" />
      <el-table-column prop="generator" label="操作人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.generatedDate">{{ scope.row.generatedDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="260"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CG_SHOPPING_LIST_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CG_SHOPPING_LIST_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            v-if="checkPermission(['CG_SHOPPING_LIST_GENERATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-document"
            size="small"
            @click.stop="handleGenerateAccount(scope.$index, scope.row)"
          >
            生成采购台账
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="shoppingListPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addShoppingList,
  deleteShoppingList,
  updateShoppingList,
  selectShoppingListInfo,
  selectShoppingListList
} from '@/api/purchase/shoppingList'
import { selectRequisitionList } from '@/api/purchase/requisition'
import { addLedger, selectLedgerByPurchasingListId } from '@/api/purchase/ledger'
import moment from 'moment'
import XEUtils from 'xe-utils'

export default {
  data () {
    return {
      shoppingListDialogVisible: false,
      shoppingListFormTitle: '',
      shoppingListForm: {
        id: '',
        purchaseListNo: '',
        ids: ''
      },
      shoppingListFormRules: {
        purchaseListNo: [{ required: true, message: '编号不能为空', trigger: ['blur', 'change']}]
      },
      shoppingListPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        purchaseListNo: ''
      },
      requisitionList: [],
      detailList: []
    }
  },
  created () {
    selectShoppingListList(this.searchForm).then(res => {
      this.shoppingListPage = res
    })
  },
  methods: {
    shoppingListDialogClose () {
      this.$refs.shoppingListFormRef.resetFields()
      this.$refs.requisitionTable.clearSelection()
      this.requisitionList = []
      this.detailList = []
    },
    shoppingListFormSubmit () {
      if (this.shoppingListFormTitle === '采购清单详情') {
        this.shoppingListDialogVisible = false
        return
      }
      if (this.shoppingListFormTitle === '修改采购清单') {
        updateShoppingList({
          id: this.shoppingListForm.id,
          detailJson: JSON.stringify(this.$refs.xTableInfo.getTableData().tableData)
        }).then(async res => {
          this.shoppingListPage = await selectShoppingListList(this.searchForm)
          this.shoppingListDialogVisible = false
        })
        return
      }
      if (this.shoppingListFormTitle === '生成采购台账') {
        addLedger({
          purchasingListId: this.shoppingListForm.id,
          ledgerJson: JSON.stringify(this.$refs.xTable.getTableData().tableData)
        }).then(async res => {
          this.shoppingListPage = await selectShoppingListList(this.searchForm)
          this.shoppingListDialogVisible = false
        })
        return
      }
      this.$refs.shoppingListFormRef.validate(async valid => {
        if (valid) {
          if (this.shoppingListFormTitle === '新增采购清单') {
            this.shoppingListForm.id = ''
            await addShoppingList(this.shoppingListForm)
          }
          this.shoppingListPage = await selectShoppingListList(this.searchForm)
          this.shoppingListDialogVisible = false
        }
      })
    },
    handleAdd () {
      selectRequisitionList({ statusFlag: 1 }).then(res => {
        this.requisitionList = res.list
      })
      this.shoppingListFormTitle = '新增采购清单'
      this.shoppingListDialogVisible = true
      this.shoppingListForm.purchaseListNo = 'CG' + moment().format('YYYYMMDD')
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteShoppingList(row.id)
        if (this.shoppingListPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.shoppingListPage = await selectShoppingListList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.shoppingListFormTitle = '修改采购清单'
      this.shoppingListDialogVisible = true
      this.selectShoppingListInfoById(row.id)
    },
    handleInfo (index, row) {
      this.shoppingListFormTitle = '采购清单详情'
      this.shoppingListDialogVisible = true
      this.selectShoppingListInfoById(row.id)
    },
    handleGenerateAccount (index, row) {
      this.shoppingListFormTitle = '生成采购台账'
      this.shoppingListDialogVisible = true
      if (row.status === 1) {
        selectShoppingListInfo(row.id).then(res => {
          this.shoppingListForm.id = res.id
          this.shoppingListForm.purchaseListNo = res.purchaseListNo
          this.detailList = res.detailList.map(item => {
            return {
              purchasingListId: item.purchasingListId,
              materialNo: item.materialNo,
              materialName: item.materialName,
              factory: item.factory,
              spec: item.spec,
              unitPrice: item.unitPrice,
              quantity: item.quantity,
              amount: item.totalPrice,
              remarks: item.remarks
            }
          })
        })
      } else {
        selectLedgerByPurchasingListId({
          purchasingListId: row.id
        }).then(res => {
          this.shoppingListForm.id = res.id
          this.shoppingListForm.purchaseListNo = res.purchaseListNo
          this.detailList = res.ledgerList
        })
      }
    },
    selectShoppingListInfoById (id) {
      selectShoppingListInfo(id).then(res => {
        this.shoppingListForm.id = res.id
        this.shoppingListForm.purchaseListNo = res.purchaseListNo
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectShoppingListList(this.searchForm).then(res => {
        this.shoppingListPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectShoppingListList(this.searchForm).then(res => {
        this.shoppingListPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectShoppingListList(this.searchForm).then(res => {
        this.shoppingListPage = res
      })
    },
    handleSelectionChange (value) {
      this.shoppingListForm.ids = value.map(item => item.id).join(',')
    },
    countAmount (row) {
      row.totalPrice = XEUtils.multiply(row.quantity, row.unitPrice)
      return row.totalPrice
    },
    countAmount2 (row) {
      row.amount = XEUtils.multiply(row.quantity, row.unitPrice)
      return row.amount
    }
  }
}
</script>

<style>
#shoppingList .el-dialog__body {
  padding: 0px 20px 20px 20px;
}
#shoppingList .el-table__expanded-cell[class*=cell] {
  padding: 20px;
}
</style>
