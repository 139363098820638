import axios from '@/common/axios'
import qs from 'qs'

export function addRequisition (data) {
  return axios({
    method: 'post',
    url: '/purchase/requisition/add',
    data: qs.stringify(data)
  })
}

export function deleteRequisition (id) {
  return axios({
    method: 'delete',
    url: '/purchase/requisition/delete/' + id
  })
}

export function updateRequisition (data) {
  return axios({
    method: 'put',
    url: '/purchase/requisition/update',
    data: qs.stringify(data)
  })
}

export function selectRequisitionInfo (id) {
  return axios({
    method: 'get',
    url: '/purchase/requisition/info/' + id
  })
}

export function selectRequisitionList (query) {
  return axios({
    method: 'get',
    url: '/purchase/requisition/list',
    params: query
  })
}

export function approvalRequisition (data) {
  return axios({
    method: 'put',
    url: '/purchase/requisition/updateApprove',
    data: qs.stringify(data)
  })
}

export function confirmRequisition (data) {
  return axios({
    method: 'put',
    url: '/purchase/requisition/updateReview',
    data: qs.stringify(data)
  })
}
