import axios from '@/common/axios'
import qs from 'qs'

export function addShoppingList (data) {
  return axios({
    method: 'post',
    url: '/purchase/purchasingList/add',
    data: qs.stringify(data)
  })
}

export function deleteShoppingList (id) {
  return axios({
    method: 'delete',
    url: '/purchase/purchasingList/delete/' + id
  })
}

export function updateShoppingList (data) {
  return axios({
    method: 'put',
    url: '/purchase/purchasingList/update',
    data: qs.stringify(data)
  })
}

export function selectShoppingListInfo (id) {
  return axios({
    method: 'get',
    url: '/purchase/purchasingList/info/' + id
  })
}

export function selectShoppingListList (query) {
  return axios({
    method: 'get',
    url: '/purchase/purchasingList/list',
    params: query
  })
}

export function getShoppingList () {
  return axios({
    method: 'get',
    url: '/purchase/purchasingList/getShoppingList'
  })
}

